import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import SafeHtmlParser from "./safeHtmlParser";
import TickIcon from "../images/circleTick.svg";
import StarsIcon from "../images/5stars.svg";

const ExpertCardPopup = () => {
	return (
		<div
			style={{
				background: "#E6F7F7",
				borderTopLeftRadius: "12px",
				borderTopRightRadius: "12px",
			}}
			className="py-4 px-md-4 px-lg-5 px-3"
		>
			<h3 className="fs-5">Free Expert Guidance</h3>
			<div className="d-md-flex  py-2 align-items-center">
				<div className="d-flex pb-2 py-md-0 me-3 me-md-4 me-lg-2 me-xl-4">
					<img
						className=" me-2"
						src={TickIcon}
						style={{
							maxWidth: "20px",
							maxHeight: "20px",
						}}
					/>
					<p style={{ fontSize: "90%" }} className="fw-bold py-0 my-0">
						Tailored Quick Quotes
					</p>
				</div>
				<div className="d-flex pb-2 py-md-0 me-3 me-md-4 me-lg-2 me-xl-4">
					<img
						className=" me-2"
						src={TickIcon}
						style={{
							maxWidth: "20px",
							maxHeight: "20px",
						}}
					/>
					<p style={{ fontSize: "90%" }} className="fw-bold py-0 my-0">
						Flexible Viewings
					</p>
				</div>
				<div className="d-flex  py-md-0 me-3 me-md-4 me-lg-2 me-xl-4">
					<img
						className=" me-2"
						src={TickIcon}
						style={{
							maxWidth: "20px",
							maxHeight: "20px",
						}}
					/>
					<p style={{ fontSize: "90%" }} className="fw-bold py-0 my-0">
						Guaranteed Best Deals
					</p>
				</div>
			</div>
			<div className="d-flex align-items-center">
				<p className="fw-bold fs-5 py-0 my-0">
					Rated{" "}
					<span className="fw-bold" style={{ color: "#FEA500" }}>
						5.0
					</span>
				</p>
				<img
					className="me-3 ms-2"
					src={StarsIcon}
					style={{
						maxWidth: "80px",
					}}
				/>
				<p className="fw-bold fs-5 py-0 my-0">Google</p>
			</div>
		</div>
	);
};

export default ExpertCardPopup;
